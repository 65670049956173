// src/utils/snowflakeUtil.js

/**
 * 生成指定長度的隨機整數 ID
 * @param {number} length ID 的長度
 * @returns {string} 生成的 ID
 */
function generateIntegerId(length) {
  let result = '';
  for (let i = 0; i < length; i++) {
    // 對於第一位，我們確保它不是 0
    if (i === 0) {
      result += Math.floor(Math.random() * 9) + 1;
    } else {
      result += Math.floor(Math.random() * 10);
    }
  }
  return result;
}

/**
 * 生成 10 位長度的整數 ID
 * @returns {string} 10 位長度的整數 ID
 */
function generateTenDigitId() {
  return generateIntegerId(10);
}

export {
  generateIntegerId,
  generateTenDigitId
};