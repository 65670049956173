<template>
  <div class="view-price full-screen">
    <div class="header">
      <div class="logo-container">
        <img src="@/assets/logo.png" alt="Logo" class="logo">
        <span class="shop-name">{{ $t('price.shopName') }}</span>
      </div>
      <div class="button-group">
        <button @click="toggleFullScreen" class="header-btn fullscreen-btn">{{ $t('price.fullscreen') }}</button>
        <button @click="resetToHome" class="header-btn home-btn">{{ $t('price.home') }}</button>
        <button @click="showBuyModal = true" class="header-btn buy-btn">{{ $t('price.buy') }}</button>
        <button @click="showSellModal = true" class="header-btn sell-btn">{{ $t('price.sell') }}</button>
        <button @click="fetchLatestOrder" class="header-btn order-btn">{{ $t('price.orderDetails') }}</button>
        <div class="dropdown language-dropdown">
          <button @click="toggleLanguageDropdown" class="header-btn language-btn">
            {{ currentLanguage }}
            <span class="arrow-down">▼</span>
          </button>
          <div v-if="showLanguageDropdown" class="dropdown-content">
            <a href="#" @click="setLanguage('zh-TW')">繁體中文</a>
            <a href="#" @click="setLanguage('zh-CN')">简体中文</a>
            <a href="#" @click="setLanguage('en')">English</a>
          </div>
        </div>
        <div class="dropdown">
          <button @click="toggleDropdown" :class="['header-btn', 'menu-btn', { 'alert': currentStatus !== $t('price.open') }]">
            {{ currentStatus }}
            <span class="arrow-down">▼</span>
          </button>
          <div v-if="showDropdown" class="dropdown-content">
            <a href="#" @click="setStatus($t('price.open'), 'open')">{{ $t('price.open') }}</a>
            <a href="#" @click="setStatus($t('price.busy'), 'busy')">{{ $t('price.busy') }}</a>
            <a href="#" @click="setStatus($t('price.out'), 'out')">{{ $t('price.out') }}</a>
            <a href="#" @click="setStatus($t('price.closed'), 'closed')">{{ $t('price.closed') }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="main-content" v-if="!showOrderDetails">
      <div 
        class="buy-usdt" 
        :class="{ 'highlight': isBuyUsdtHovered }" 
        @mouseover="isBuyUsdtHovered = true" 
        @mouseleave="isBuyUsdtHovered = false"
      >
        <h2 class="section-title buy-title">{{ $t('price.buyUSDT') }}</h2>
        <div class="buy-usdt-text">
          <div class="price">{{ buyPrice }}</div>
          <div class="price large-price">{{ buyPriceLarge }} <span class="price-label">{{ $t('price.largeOrderThreshold') }}</span></div>
          <div class="buy-text-group">
            <div class="buy-text zh-tw">{{ $t('price.hkdToUSDT') }}</div>
          </div>
          <div class="order-hint">
            <p>{{ $t('price.minOrderAmount', { amount: '500 HKD' }) }}</p>
            <p>{{ $t('price.feeApplied', { amount: '20000', fee: '30' }) }}</p>
          </div>
          <div class="qr-code-container">
            <qrcode-vue value="https://order.pcdao.org/buy" :size="200" level="H"></qrcode-vue>
          </div>
        </div>
      </div>
      <div 
        class="sell-usdt" 
        :class="{ 'highlight': isSellUsdtHovered }" 
        @mouseover="isSellUsdtHovered = true" 
        @mouseleave="isSellUsdtHovered = false"
      >
        <h2 class="section-title sell-title">{{ $t('price.sellUSDT') }}</h2>
        <div class="sell-usdt-text">
          <div class="price">{{ sellPrice }}</div>
          <div class="price large-price">{{ sellPriceLarge }} <span class="price-label">{{ $t('price.largeOrderThreshold') }}</span></div>
          <div class="sell-text-group">
            <div class="sell-text zh-tw">{{ $t('price.USDTToHKD') }}</div>
          </div>
          <div class="order-hint">
            <p>{{ $t('price.minOrderAmount', { amount: '500 HKD' }) }}</p>
            <p>{{ $t('price.noFeeAbove500') }}</p>
          </div>
          <div class="qr-code-container">
            <qrcode-vue value="https://order.pcdao.org/sell" :size="200" level="H"></qrcode-vue>
          </div>
        </div>
      </div>
    </div>
    <div class="main-content order-details" v-else>
      <div class="left-section">
        <h2 class="section-title">{{ $t('price.receiveUsdtAddressQrCode') }}</h2>
        <div class="qr-container">
          <qrcode-vue :value="orderData.receive_address" :width="260" :height="260" level="H"></qrcode-vue>
        </div>
        <p class="chain-name">{{ $t('price.chainName') }}: {{ getChainFullName(orderData.chain_type) }}</p>
        <p class="address clickable" @click="openBlockchainExplorer(orderData.receive_address, orderData.chain_type)">
          {{ orderData.receive_address }}
        </p>
        <p class="usdt-amount">{{ $t('price.receiveUsdtAmount') }}: {{ formatNumberWithCommas(orderData.usdt_amount) }} USDT</p>
      </div>
      <div class="right-section">
        <h2 class="section-title">{{ $t('price.orderDetails') }}</h2>
        <div class="order-info">
          <div class="info-row">
            <span class="info-label">{{ $t('price.orderNumber') }}:</span>
            <span class="info-value">{{ orderData.order_number }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">{{ $t('price.orderType') }}:</span>
            <span class="info-value">{{ orderData.order_type === 'sell' ? $t('price.customerReceiveHkd') : $t('price.customerReceiveUsdt') }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">{{ $t('price.usdtAmount') }}:</span>
            <span class="info-value">{{ formatNumberWithCommas(orderData.usdt_amount) }} USDT</span>
          </div>

          <div class="info-row">
            <span class="info-label">{{ $t('price.exchangeRate') }}:</span>
            <span class="info-value">{{ orderData.exchange_rate }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">{{ $t('price.hkdAmount') }}:</span>
            <span class="info-value">{{ formatNumberWithCommasNoDecimals(orderData.amount) }} HKD</span>
          </div>
         
          <div class="info-row">
            <span class="info-label">{{ $t('price.fee') }}:</span>
            <span class="info-value">{{ formatNumberWithCommasNoDecimals(orderData.fee_hkd) }} HKD</span>
          </div>
          <div class="info-row">
            <span class="info-label">{{ $t('price.createdTime') }}:</span>
            <span class="info-value">{{ formatDate(orderData.created_at) }}</span>
          </div>
        </div>
        <!-- 增加打印收據和打印A4收據按鈕 -->
        <div class="button-group print-buttons">
          <button @click="printReceipt" class="header-btn print-btn">{{ $t('price.printReceipt') }}</button>
          <button @click="addPrintRecord(orderData.order_number)" class="header-btn print-btn">{{ $t('price.printReceipt') }}2</button>
          <button @click="printA4Receipt" class="header-btn print-btn">{{ $t('price.printA4Receipt') }}</button>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-content">
        <div class="datetime">
          {{ currentDate }} {{ currentTime }}
        </div>
        <div class="large-order-threshold">
          {{ $t('price.largeOrderMessage', { 
            amount: formatNumberWithCommasNoDecimals(largeOrderThreshold) 
          }) }}
        </div>
      </div>
    </div>

    <!-- 買入彈窗 -->
    <div v-if="showBuyModal" class="modal" style="justify-content: flex-end; align-items: flex-end;">
      <div class="modal-content" style="width: 50%; height: 100vh; margin-right: 0;">
        <span class="close" @click="showBuyModal = false">&times;</span>
        <iframe src="/buy/true" class="iframe-content" ></iframe>
      </div>
    </div>

    <!-- 賣出彈窗 -->
    <div v-if="showSellModal" class="modal" style="justify-content: flex-start; align-items: flex-start;">
      <div class="modal-content" style="width: 50%; height: 100vh; margin-left: 0;">
        <span class="close" @click="showSellModal = false">&times;</span>
        <iframe src="/sell/true" class="iframe-content"></iframe>
      </div>
    </div>

    <!-- 新增區塊鏈瀏覽器模態框 -->
    <div v-if="showExplorerModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showExplorerModal = false">&times;</span>
        <iframe :src="explorerUrl" class="iframe-content"></iframe>
      </div>
    </div>

    <!-- 新增打印收據模態框 -->
    <div v-if="showPrintModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="showPrintModal = false">&times;</span>
        <iframe :src="printUrl" class="iframe-content"></iframe>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import axios from 'axios';
import QrcodeVue from "vue-qrcode";
import { formatNumberWithCommas, formatNumberWithCommasNoDecimals } from "@/utils/formatNumber";
import { API_BASE_URL, PRICE_API_URL } from "@/apiConfig";
import { ElMessage } from 'element-plus';
import api from '@/utils/api';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const currentDate = ref('');
const currentTime = ref('');
const isBuyUsdtHovered = ref(false);
const isSellUsdtHovered = ref(false);
const showBuyModal = ref(false);
const showSellModal = ref(false);
const showOrderDetails = ref(false);
const orderData = ref(null);
const buyPrice = ref('');
const sellPrice = ref('');
const buyPriceLarge = ref('');
const sellPriceLarge = ref('');
const largeOrderThreshold = ref(0);
const currentStatus = ref('正在營業');
const showDropdown = ref(false);
const showExplorerModal = ref(false);
const explorerUrl = ref('');
const showPrintModal = ref(false);
const printUrl = ref('');
const assetsAddresses = ref([]); // 新增：用于存储地址列表

const currentLanguage = computed(() => {
  switch (locale.value) {
    case 'zh-TW':
      return '繁體中文';
    case 'zh-CN':
      return '简体中文';
    case 'en':
      return 'English';
    default:
      return '繁體中文';
  }
});

const showLanguageDropdown = ref(false);

const toggleLanguageDropdown = () => {
  showLanguageDropdown.value = !showLanguageDropdown.value;
};

const setLanguage = (lang) => {
  locale.value = lang;
  showLanguageDropdown.value = false;
};

const updateDateTime = () => {
  const now = new Date();
  const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  currentDate.value = `${now.getFullYear()}年${now.getMonth() + 1}月${now.getDate()}日 ${weekdays[now.getDay()]}`;
  currentTime.value = now.toLocaleTimeString('zh-TW', { hour12: false });
};

const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
  }
};

const resetToHome = () => {
  showOrderDetails.value = false;
  orderData.value = null;
  showBuyModal.value = false;
  showSellModal.value = false;
  fetchPrices();
};

const fetchLatestOrder = async () => {
  try {
    const response = await axios.get('https://order.pcdao.org/api/preorders?order_number=0');
    console.log(response.data);
    orderData.value = response.data;
    showOrderDetails.value = true;
  } catch (error) {
    console.error('Error fetching order data:', error);
  }
};

const fetchAssetsAddresses = async () => {
  try {
    const response = await api.get('/assets_addresses');
    console.log('Assets Addresses:', response.data);
    assetsAddresses.value = response.data.data; // 保存地址列表
  } catch (error) {
    console.error("Error fetching assets addresses:", error);
  }
};

const addPrintRecord = async (orderNumber) => {
  try {
    const printLanguage = locale.value === 'en' ? 'en' : 'zh';
    await api.post('/add_print_record', {
      order_number: orderNumber,
      print_language: printLanguage
    });
    ElMessage.success(t('price.printRecordAddedSuccess'));
  } catch (error) {
    console.error('添加打印记录时出错:', error);
    ElMessage.error(t('price.printRecordAddFailed'));
  }
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString();
};

const fetchPrices = async () => {
  try {
    const response = await axios.get(PRICE_API_URL);
    const data = response.data;
    console.log(data);
    buyPrice.value = data.buyPrice;
    sellPrice.value = data.sellPrice;
    buyPriceLarge.value = data.buyPriceLarge;
    sellPriceLarge.value = data.sellPriceLarge;
    largeOrderThreshold.value = data.largeOrderThreshold;
    currentStatus.value = getDisplayStatus(data.promptStatus);
  } catch (error) {
    console.error('获取价格时出错:', error);
  }
};

const getDisplayStatus = (promptStatus) => {
  const statusMap = {
    'open': '正在營業',
    'busy': '有客在店',
    'out': '臨時外出',
    'closed': '休息放假'
  };
  return statusMap[promptStatus] || '未知状态';
};

const toggleDropdown = () => {
  showDropdown.value = !showDropdown.value;
};

const setStatus = async (displayStatus, promptStatus) => {
  currentStatus.value = displayStatus;
  showDropdown.value = false;
  try {
    const form = { promptStatus: promptStatus };
    await api.post(`${API_BASE_URL}/update_price`, form);
    ElMessage.success('狀態更新成功');
  } catch (error) {
    console.error('更新狀態時出錯:', error);
    ElMessage.error('更新狀態失敗');
  }
};

const openBlockchainExplorer = (address, chainType) => {
  console.log('方法被調用,參數:', address, chainType);
  let baseUrl = '';
  switch(chainType.toLowerCase()) {
    case 'tron':
      baseUrl = 'https://tronscan.org/#/address/';
      break;
    case 'eth':
      baseUrl = 'https://etherscan.io/address/';
      break;
    default:
      console.error('未知的鏈類型');
      return;
  }
  explorerUrl.value = baseUrl + address;
  showExplorerModal.value = true;
  console.log('設置的 explorerUrl:', explorerUrl.value);
  console.log('showExplorerModal 設置為:', showExplorerModal.value);
};

const printReceipt = () => {
  printUrl.value = '/print-receipt/0';
  showPrintModal.value = true;
};

const printA4Receipt = () => {
  const orderType = orderData.value.order_type;
  const orderNumber = orderData.value.order_number;
  printUrl.value = orderType === 'sell' ? `/receipt-sell/${orderNumber}` : `/receipt-buy/${orderNumber}`;
  showPrintModal.value = true;
};

// 新增：根据 chain_type 获取 chain_full_name
const getChainFullName = (chainType) => {
  const address = assetsAddresses.value.find(addr => addr.chain_short_name === chainType);
  return address ? address.chain_full_name : '未知链';
};

onMounted(() => {
  updateDateTime();
  setInterval(updateDateTime, 1000);
  fetchPrices();
  fetchAssetsAddresses(); // 调用 fetchAssetsAddresses 函数
  const priceInterval = setInterval(fetchPrices, 30000);

  onUnmounted(() => {
    clearInterval(priceInterval);
  });
});
</script>

<style scoped>
.view-price {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: black; /* 确保这是你想要的背景色 */
  color: white;
}

.header {
  height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.shop-name {
  font-size: 1.5vw;
  font-weight: bold;
}

.button-group {
  display: flex;
  gap: 10px;
}

.header-btn {
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1vw;
  cursor: pointer;
  border-radius: 5px;
}

.fullscreen-btn {
  background-color: #4CAF50;
}

.home-btn {
  background-color: #2196F3;
}

.buy-btn {
  background-color: #4CAF50;
}

.sell-btn {
  background-color: #FF8A65;
}

.order-btn {
  background-color: #FFC107;
}

.main-content {
  display: flex;
  height: 90%;
}

.buy-usdt, .sell-usdt {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.buy-usdt-text, .sell-usdt-text {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-size: 5vw; /* 從4vw增加到5vw */
  font-weight: bold;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding: 10px 20px;
  border-radius: 10px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 0 0 10px currentColor;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
    text-shadow: 0 0 20px currentColor;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 0 0 10px currentColor;
  }
}

.price-text {
  font-size: 2vw;
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  line-height: 1.8;
}

.footer {
  height: 10%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.datetime {
  font-size: 1.5vw;
  white-space: nowrap;
}

.large-order-threshold {
  font-size: 1.5vw;
  color: #FFC107;
  text-align: right;
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.highlight {
  background-color: rgba(255, 255, 255, 0.1);
}

.modal {
  display: flex;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: black; 
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  height: 80%;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.iframe-content {
  width: 100%;
  height: calc(100% - 40px);
  border: none;
  background-color: transparent; /* 确保 iframe 背景透明 */
}

.order-details {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  padding: 20px;
}

.left-section, .right-section {
  width: 45%;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.left-section {
  justify-content: space-between;
  flex: 1 0 auto;
}

.right-section {
  justify-content: flex-start;
}

.qr-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.section-title {
  font-size: 3vw;
  margin-bottom: 30px;
}

.buy-title {
  background-color: rgba(76, 175, 80, 0.3);
  color: #4CAF50;
  font-size: 5vw;
}

.sell-title {
  background-color: rgba(255, 138, 101, 0.3); 
  color: #FF8A65;
  font-size: 5vw;
}

.chain-name, .address, .usdt-amount {
  margin-top: 20px;
}

.chain-name {
  font-size: 2vw;
  font-weight: bold;
  color: #FFC107;
}

.address {
  font-family: monospace;
  font-size: 1.5vw;
  word-break: break-all;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 5px;
  line-height: 1.6;
}

.usdt-amount {
  font-size: 2.5vw;
  font-weight: bold;
  color: #4CAF50;
}

.order-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-row {
  display: flex;
  justify-content: space-between;
  font-size: 1.4vw;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 15px;
  padding-bottom: 15px;
}

.info-label {
  font-weight: bold;
  color: #FFC107;
  font-size: 1.5vw; /* 使用 vw 单位按比例调整字体大小 */
}

.info-value {
  text-align: right;
  font-size: 1.5vw; /* 使用 vw 单位按比例调整字体大小 */
  color: #ffffff; /* 将文字颜色更改为白色 */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.menu-btn {
  background-color: #2c3e50; /* 深色背景 */
  color: white;
  padding: 10px 20px;
  font-size: 1vw;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.menu-btn.alert {
  background-color: #FF0000; /* 當狀態不是正在營業時，背景顏色變為紅色 */
  animation: blink 1s infinite; /* 添加閃爍效果 */
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.arrow-down {
  margin-left: 5px;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: #34495e; /* 深色背景 */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.5);
  z-index: 1;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown-content a {
  color: #ecf0f1; /* 淺色文字 */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

.dropdown-content a:hover {
  background-color: #2c3e50; /* 深色停效果 */
}

.price {
  font-size: 6vw;
  font-weight: bold;
  margin-bottom: 15px;
}

.large-price {
  font-size: 4vw;
  margin-bottom: 30px;
}

.price-label {
  font-size: 2vw;
  font-weight: normal;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
}

.buy-text, .sell-text {
  font-size: 2vw; /* 從1.5vw增加到2vw */
  margin-bottom: 20px; /* 從10px增加到20px，增加行間距 */
}

.clickable {
  cursor: pointer;
  text-decoration: none; /* 移除下劃線 */
}

.clickable:hover {
  color: #FFC107;
}

/* 添加新的樣式 */
.address.clickable {
  transition: color 0.3s ease; /* 添加顏色過渡效果 */
}

.address.clickable:hover {
  color: #FFC107; /* 懸停時改變顏色 */
}

.buy-text-group, .sell-text-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 新增訂單提示樣式 */
.order-hint {
  margin-top: 20px;
  font-size: 1.5vw;
  color: #FFC107;
  text-align: center;
}

.print-btn {
  background-color: #FFC107;
  color: black;
  padding: 10px 20px;
  font-size: 1vw;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.print-buttons {
  display: flex;
  gap: 10px;
}

.language-dropdown {
  position: relative;
}

.language-btn {
  background-color: #2c3e50;
  color: white;
  padding: 10px 20px;
  font-size: 1vw;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.buy-usdt .qr-code-container {
  border: 2px solid var(--success-color);
}

.sell-usdt .qr-code-container {
  border: 2px solid var(--warning-color);
}
</style>


