import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import BuyPage from '../views/BuyPage.vue'
import SellPage from '../views/SellPage.vue'
import ReceiptBuyPage from '../views/ReceiptBuyPage.vue'
import ReceiptSellPage from '../views/ReceiptSellPage.vue'
import PendingOrders from '../views/PendingOrders.vue'
import DashboardView from '@/views/Dashboard.vue'
import SetQuotes from '@/views/SetQuotes.vue'
import MemberLogin from '@/views/MemberLogin.vue'
import MemberPage from '@/views/MemberPage.vue'
import LoginPage from '@/views/LoginPage.vue' // 引入 LoginPage 組件
import FinancePage from '@/views/FinancePage.vue'
import ViewPrice from '@/views/ViewPrice.vue'
import FinancialAccountsPage from '@/views/FinancialAccountsPage.vue';
import DisplayData from '@/views/DisplayData.vue';
const routes = [
  {
    path: '/',
    redirect: '/dashboard' // 重定向到登录页面
  },
  {
    path: '/buy/:allowAdjust?',
    name: 'Buy',
    component: BuyPage,
    props: route => ({
      allowAdjust: route.params.allowAdjust === 'true' || false, // 默认值为 false
    }),
  },
  {
    path: '/sell/:allowAdjust?',
    name: 'Sell',
    component: SellPage,
    props: route => ({
      allowAdjust: route.params.allowAdjust === 'true' || false, // 默认值为 false
    }),
  },
  {
    path: '/receipt-buy/:orderNumber',
    name: 'ReceiptBuy',
    component: ReceiptBuyPage,
    props: true
  },
  {
    path: '/receipt-sell/:orderNumber',
    name: 'ReceiptSell',
    component: ReceiptSellPage,
    props: true
  },
  {
    path: '/pending-orders',
    name: 'PendingOrders',
    component: PendingOrders,
    meta: { requiresAuth: true } // 如果需要身份验证
  },
  {
    path: '/print-receipt/:orderNumber',
    name: 'PrintReceipt',
    component: () => import('../views/PrintReceipt.vue'),
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/set-quotes',
    name: 'SetQuotes',
    component: SetQuotes,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/member-login',
    name: 'MemberLogin',
    component: MemberLogin
  },
  {
    path: '/member',
    name: 'MemberPage',
    component: MemberPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/finance',
    name: 'Finance',
    component: FinancePage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/price',
    name: 'ViewPrice',
    component: ViewPrice,
    meta: { requiresAuth: true }
  },
  {
    path: '/financial-accounts',
    name: 'FinancialAccounts',
    component: FinancialAccountsPage,
    meta: { requiresAuth: true, requiresAdmin: true }
  },
  {
    path: '/display',
    name: 'DisplayData',
    component: DisplayData,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!userStore.isLoggedIn) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
