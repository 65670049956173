<template>
  <div>
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="20">
        <h1>金融賬戶列表</h1>

        <el-row>
      <el-col :span="16">
        <el-row :gutter="20" style="margin-bottom: 20px;">
          <el-col :span="6">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>全部銀行卡合計餘額</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalBankCardBalance) }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>全部現金餘額</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalCashBalance) }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>全部交易所與錢包餘額</span>
                </div>
              </template>
              <div class="card-content">
                {{ formatNumberWithCommasNoDecimals(totalWalletExchangeBalance) }} HKD
              </div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="hover">
              <template #header>
                <div class="card-header">
                  <span>USDT 單位成本</span>
                </div>
              </template>
              <div class="card-content">
                {{ usdtUnitCost }} HKD
              </div>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" style="text-align: right;">
        <el-button type="primary" @click="openDialog">操作資金</el-button>
        <el-button type="success" @click="refreshData" style="margin-left: 10px;">刷新數據</el-button>
      </el-col>
    </el-row>
    
        <el-dialog v-model="dialogVisible" title="操作資金">
          <el-form :model="form" label-width="120px">
            <el-form-item label="操作類型">
              <el-radio-group v-model="form.operation_type" @change="updateAccountOptions">
                <el-radio label="buy">客戶買入</el-radio>
                <el-radio label="sell">客戶賣出</el-radio>
                <el-radio label="internal_transfer_hkd">內部轉帳(HKD)</el-radio> <!-- 修改名稱 -->
                <el-radio label="internal_transfer_usdt">內部轉帳(USDT)</el-radio> <!-- 新增選項 -->
              </el-radio-group>
            </el-form-item>
            <el-form-item label="來源賬戶ID">
              <el-select v-model="form.source_account_id" placeholder="選擇來源賬戶">
                <el-option
                  v-for="account in sourceAccounts"
                  :key="account.id"
                  :label="`${account.type === 'cash' ? '現金' : 
                            account.type === 'bank_card' ? '銀行卡' :
                            account.type === 'exchange' ? '交易所' :
                            account.type === 'wallet' ? '錢包' : 
                            account.type} - ${account.name} ${account.bank_name ? `(${account.bank_name})` : ''} (餘額: ${
                            account.type === 'wallet' || account.type === 'exchange' 
                              ? `${formatNumberWithCommasNoDecimals(account.balance_usdt_available)} USDT`
                              : `${formatNumberWithCommasNoDecimals(account.balance_hkd)} HKD`
                          })`"
                  :value="account.id"
                ></el-option>
              </el-select>
              
            </el-form-item>
            <el-form-item label="來源金額(HKD)">
              <el-input v-model="form.source_amount" :disabled="form.operation_type === 'internal_transfer_usdt'">
                <template #append>
                  <span style="width: 60px; text-align: center;">HKD</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item v-if="form.operation_type !== 'internal_transfer_hkd'" label="目標賬戶ID">
              <el-radio-group v-model="form.target_account_id">
                <el-radio
                  v-for="account in targetAccounts"
                  :key="account.id"
                  :label="account.id"
                >
                  {{ `${account.type === 'wallet' ? '錢包' : '交易所'} - ${account.name} (${formatNumberWithCommasNoDecimals(account.balance_usdt_available)} USDT)` }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="form.operation_type === 'internal_transfer_hkd'" label="目標賬戶ID">
              <el-select v-model="form.target_account_id" placeholder="選擇目標賬戶">
                <el-option
                  v-for="account in targetAccounts"
                  :key="account.id"
                  :label="`${account.type === 'cash' ? '現金' : 
                            account.type === 'bank_card' ? '銀行卡' :
                            account.type === 'exchange' ? '交易所' :
                            account.type === 'wallet' ? '錢包' : 
                            account.type} - ${account.name} (${account.bank_name || '現金帳戶'}) (餘額: ${formatNumberWithCommasNoDecimals(account.balance_hkd)} HKD)`"
                  :value="account.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="目標金額(USDT)">
              <el-input v-model="form.target_amount" :disabled="form.operation_type === 'internal_transfer_hkd'">
                <template #append>
                  <span style="width: 60px; text-align: center;">USDT</span>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="當前匯率" v-if="form.operation_type !== 'internal_transfer_hkd' && form.operation_type !== 'internal_transfer_usdt'">
              <el-input v-model="currentRate">
                <template #append>
                  <span style="width: 60px; text-align: center;">
                    HKD/USDT
                  </span>
                </template>
              </el-input>
            </el-form-item>
          </el-form>
          <template v-slot:footer>
            <el-button type="danger" @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitOperation">提交</el-button>
          </template>
        </el-dialog>
        <el-dialog v-model="editDialogVisible" title="編輯賬戶">
          <el-form :model="editForm" label-width="120px">
            <el-form-item label="名稱">
              <el-input v-model="editForm.name"></el-input>
            </el-form-item>
            <el-form-item label="類型">
              <el-select v-model="editForm.type" placeholder="選擇類型">
                <el-option label="現金" value="cash"></el-option>
                <el-option label="銀行卡" value="bank_card"></el-option>
                <el-option label="錢包" value="wallet"></el-option>
                <el-option label="交易所" value="exchange"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="editForm.type !== 'wallet' && editForm.type !== 'exchange'" label="餘額 (HKD)">
              <el-input v-model="editForm.balance_hkd"></el-input>
            </el-form-item>
            <el-form-item v-if="editForm.type === 'wallet' || editForm.type === 'exchange'" label="USDT 餘額">
              <el-input v-model="editForm.balance_usdt_available"></el-input>
            </el-form-item>
            <!-- 可以根据需要添加更多字段 -->
            <el-form-item label="銀行ID">
              <el-input v-model="editForm.bank_id"></el-input>
            </el-form-item>
            <el-form-item label="銀行名稱">
              <el-input v-model="editForm.bank_name"></el-input>
            </el-form-item>
            <el-form-item label="提款限額">
              <el-input v-model="editForm.withdrawal_limit" type="number"></el-input>
            </el-form-item>
            <el-form-item label="卡號">
              <el-input v-model="editForm.card_number"></el-input>
            </el-form-item>
            <el-form-item label="FPS ID">
              <el-input v-model="editForm.fps_id"></el-input>
            </el-form-item>
            <el-form-item label="FPS 電話">
              <el-input v-model="editForm.fps_phone"></el-input>
            </el-form-item>
            <el-form-item label="FPS 電郵">
              <el-input v-model="editForm.fps_email"></el-input>
            </el-form-item>
          </el-form>
          <template v-slot:footer>
            <el-button @click="editDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="submitEdit">提交</el-button>
          </template>
        </el-dialog>
        <el-table :data="accounts" stripe style="width: 100%">
          <el-table-column prop="id" label="ID" width="60" />
          <el-table-column prop="name" label="名稱" width="120" />
          <el-table-column prop="type" label="類型" width="100">
            <template #default="{ row }">
              {{ row.type === 'cash' ? '現金' : row.type === 'bank_card' ? '銀行卡' : row.type === 'wallet' ? '錢包' : row.type === 'exchange' ? '交易所' : row.type }}
            </template>
          </el-table-column>
          <el-table-column label="餘額 (HKD)" width="120">
            <template #default="{ row }">
              {{ formatNumberWithCommasNoDecimals(row.balance_hkd) }}
            </template>
          </el-table-column>
          <el-table-column prop="balance_usdt_available" label="USDT 餘額" width="120">
            <template #default="{ row }">
              {{ formatNumberWithCommasNoDecimals(row.balance_usdt_available) }}
            </template>
          </el-table-column>
          
          <el-table-column label="銀行名稱" width="150">
            <template #default="{ row }">
              {{ row.bank_id ? `${row.bank_id} - ${row.bank_name}` : row.bank_name }}
            </template>
          </el-table-column>
          <el-table-column prop="withdrawal_limit" label="提款限額" width="100" />
          <el-table-column prop="card_number" label="卡號" width="120"/>
          <el-table-column prop="fps_id" label="FPS ID" width="120"/>
          <el-table-column prop="fps_phone" label="FPS 電話" width="120"/>
          <el-table-column prop="fps_email" label="FPS 電郵" />
        <el-table-column label="操作" width="120">
          <template #default="{ row }">
            <el-button type="primary" size="small" @click="editAccount(row)">編輯</el-button>
          </template>
        </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { ElMessage } from 'element-plus';
import api from '@/utils/api';
import SidebarMenu from '@/components/SidebarMenu.vue';
import {
  formatNumberWithCommasNoDecimals,
} from "@/utils/formatNumber";

import { PRICE_API_URL } from "@/apiConfig";
import axios from "axios";

const accounts = ref([]);
const cashAccounts = ref([]);
const bankCardAccounts = ref([]);
const sourceAccounts = ref([]);
const targetAccounts = ref([]);
const dialogVisible = ref(false);
const form = ref({
  operation_type: '',
  source_account_id: '',
  source_amount: '',
  target_account_id: '',
  target_amount: ''
});

const usdtUnitCost = ref(0);
const buyPrice = ref(0);
const sellPrice = ref(0);
const currentRate = ref(''); // 初始化 currentRate

const totalBankCardBalance = computed(() => {
  return bankCardAccounts.value.reduce((sum, account) => sum + account.balance_hkd, 0);
});

const totalCashBalance = computed(() => {
  return cashAccounts.value.reduce((sum, account) => sum + account.balance_hkd, 0);
});

const totalWalletExchangeBalance = computed(() => {
  return accounts.value
    .filter(account => account.type === 'wallet' || account.type === 'exchange')
    .reduce((sum, account) => sum + account.balance_hkd, 0);
});

const editDialogVisible = ref(false);
const editForm = ref({
  id: '',
  name: '',
  type: '',
  balance_hkd: '',
  balance_usdt_available: '' // 添加 USDT 餘額字段
  // 可以根据需要添加更多字段
});

onMounted(() => {
  refreshData();
});

async function refreshData() {
  try {
    // 獲取最新的買入和賣出價格
    const priceResponse = await axios.get(PRICE_API_URL);
    buyPrice.value = priceResponse.data.buyPrice;
    sellPrice.value = priceResponse.data.sellPrice;

    // 設置初始匯率為賣出價格
    currentRate.value = sellPrice.value;

    // 獲取USDT的單位成本
    const usdtCostResponse = await api.get('/get_usdt_unit_cost');
    console.log(usdtCostResponse.data.data.unit_cost.toFixed(4));
    usdtUnitCost.value = parseFloat(usdtCostResponse.data.data.unit_cost.toFixed(4));

    // 發送請求獲取金融賬戶數據
    const response = await api.get('/financial_accounts', { params: { is_frequently_used: true } });
    console.log(response.data);
    accounts.value = response.data.data.map(account => {
      if (account.type === 'wallet' || account.type === 'exchange') {
        account.balance_hkd = parseFloat((account.balance_usdt_available * sellPrice.value).toFixed(2));
      }
      return account;
    }).sort((a, b) => {
      if (a.type === 'wallet' && b.type !== 'wallet') return -1;
      if (a.type !== 'wallet' && b.type === 'wallet') return 1;
      return 0;
    });
    // 過濾出類型為 'cash' 的賬戶
    cashAccounts.value = accounts.value.filter(account => account.type === 'cash');
    // 過濾出類型為 'bank_card' 的賬戶
    bankCardAccounts.value = accounts.value.filter(account => account.type === 'bank_card');
    // 初始化賬戶選項
    updateAccountOptions();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}

function updateAccountOptions() {
  if (form.value.operation_type === 'internal_transfer_hkd') {
    // 內部轉帳(HKD)時，來源和目標賬戶可以是現金或銀行卡
    sourceAccounts.value = [...cashAccounts.value, ...bankCardAccounts.value];
    targetAccounts.value = [...cashAccounts.value, ...bankCardAccounts.value];
  } else if (form.value.operation_type === 'internal_transfer_usdt') {
    // 內部轉帳(USDT)時，來源和目標賬戶可以是錢包或交易所
    sourceAccounts.value = accounts.value.filter(account => account.type === 'wallet' || account.type === 'exchange');
    targetAccounts.value = accounts.value.filter(account => account.type === 'wallet' || account.type === 'exchange');
  } else {
    // 買入或賣出時，來源賬戶為現金和銀行卡，目標賬戶為錢包和交易所
    sourceAccounts.value = [...cashAccounts.value, ...bankCardAccounts.value];
    targetAccounts.value = accounts.value.filter(account => account.type === 'wallet' || account.type === 'exchange');
    
    // 根據操作類��設置當前匯率
    if (form.value.operation_type === 'buy') {
      currentRate.value = buyPrice.value;
    } else if (form.value.operation_type === 'sell') {
      currentRate.value = sellPrice.value;
    }
  }
}

function openDialog() {
  console.log("Dialog should open");
  dialogVisible.value = true; // 確保這行代碼被執行
}

function submitOperation() {
  // 如果是內部轉帳，跳過匯率驗證
  if (form.value.operation_type !== 'internal_transfer_hkd' && form.value.operation_type !== 'internal_transfer_usdt') {
    // 計算匯率 * USDT 金額
    const calculatedHKDAmount = parseFloat(form.value.target_amount) * parseFloat(currentRate.value);
    const enteredHKDAmount = parseFloat(form.value.source_amount);

    console.log(parseFloat(form.value.target_amount));
    console.log(parseFloat(currentRate.value));
    // 計算誤差率
    const largerAmount = Math.max(calculatedHKDAmount, enteredHKDAmount);
    const smallerAmount = Math.min(calculatedHKDAmount, enteredHKDAmount);
    const errorRate = (largerAmount - smallerAmount) / smallerAmount;
    console.log('計算金額:', calculatedHKDAmount);
    console.log('輸入金額:', enteredHKDAmount);
    console.log('誤差率:', errorRate);

    // 如果誤差率大於 5%，則阻止提交
    if (errorRate > 0.05) {
      ElMessage({
        message: '匯率計算誤差超過 5%，請檢查輸入的金額。',
        type: 'error',
      });
      return;
    }
  }

  const apiEndpoint = form.value.operation_type.includes('internal_transfer') ? '/internal_transfer' : '/shop_fund_operation';
  const payload = {
    operation_type: form.value.operation_type,
    source_account_id: form.value.source_account_id,
    source_amount: form.value.source_amount,
    target_account_id: form.value.target_account_id,
    target_amount: form.value.target_amount,
    amount: form.value.operation_type === 'internal_transfer_hkd' ? form.value.source_amount : form.value.operation_type === 'internal_transfer_usdt' ? form.value.target_amount : form.value.source_amount,
    currency: form.value.operation_type === 'internal_transfer_hkd' ? 'HKD' : 'USDT'
  };

  console.log(payload);
  console.log(apiEndpoint);

  api.post(apiEndpoint, payload)
    .then(response => {
      console.log("Operation successful:", response.data);
      dialogVisible.value = false;
      refreshData(); // 刷新數據
      ElMessage({
        message: '操作成功！',
        type: 'success',
      });
    })
    .catch(error => {
      console.error("Error during operation:", error);
      ElMessage({
        message: '操作失敗，請重試。',
        type: 'error',
      });
    });
}

function editAccount(row) {
  editForm.value = { ...row }; // 复制行数据到编辑表单
  editDialogVisible.value = true;
}

function submitEdit() {
  console.log(editForm.value);
  // 发送更新请求到服务器
  api.post('/edit_financial_account', editForm.value)
    .then(response => {
      console.log("Edit successful:", response.data);
      editDialogVisible.value = false;
      refreshData(); // 刷新数据
      ElMessage({
        message: '更新成功！',
        type: 'success',
      });
    })
    .catch(error => {
      console.error("Error during edit:", error);
      ElMessage({
        message: '更新失敗，請重試。',
        type: 'error',
      });
    });
}

watch([() => form.value.source_amount, () => currentRate.value], ([newSourceAmount, newRate]) => {
  if (form.value.operation_type === 'buy' || form.value.operation_type === 'sell') {
    const sourceAmount = parseFloat(newSourceAmount);
    const rate = parseFloat(newRate);
    if (!isNaN(sourceAmount) && !isNaN(rate)) {
      form.value.target_amount = (sourceAmount / rate).toFixed(4);
    }
  }
});
</script>