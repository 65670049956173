<template>
  <div class="finance-container">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="20">
        <div style="display: flex; flex-direction: column; align-items: center; padding: 20px;">
          <h1>{{ t('message.finance') }}</h1>
          
          <el-row :gutter="20" style="margin-top: 20px;width: 100%;">
            <el-col :span="6">
              <el-card>
                <h3>{{ t('message.revenue') }}</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(revenue) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>{{ t('message.expenses') }}</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(expenses) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>{{ t('message.profit') }}</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(profit) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>{{ t('message.undistributedProfit') }}</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(undistributedProfit) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>总利润</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(totalNetProfit) }}</p>
              </el-card>
            </el-col>
          </el-row>

          <el-row :gutter="20" style="margin-top: 20px;width: 100%;">
            <el-col :span="6">
              <el-card>
                <h3>银行账户</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(financialAccountsSummary.bank_card) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>现金</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(financialAccountsSummary.cash) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>交易所</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(financialAccountsSummary.exchange) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>钱包</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(financialAccountsSummary.wallet) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>商家押金与服务质押</h3>
                <p>{{ formatNumberWithCommasTwoDecimals(financialAccountsSummary.unavailable_usdt) }}</p>
              </el-card>
            </el-col>
            <el-col :span="6">
              <el-card>
                <h3>全部合计金额</h3> <!-- 添加全部合计金额标题 -->
                <p>{{ formatNumberWithCommasTwoDecimals(totalFinancialAccounts) }}</p> <!-- 显示全部合计金额 -->
              </el-card>
            </el-col>
          </el-row>

          <!-- 日利润图表 -->
          <div id="daily-profit-chart" style="width: 100%; height: 400px; margin-top: 20px;"></div>
        </div>
      </el-col>
    </el-row>

    <div class="action-buttons">
      <el-button
        class="action-button"
        type="primary"
        @click="showAddFinanceDialog"
      >
        <span>添加财务记录</span>
      </el-button>

      <el-button
        class="action-button"
        type="success"
        @click="showDistributeProfitDialog"
      >
        <span>立即分红</span>
      </el-button>

      <el-button
        class="action-button"
        type="primary"
        @click="showAddAccountDialog"
      >
        <span>增加銀行卡/錢包</span>
      </el-button>
    </div>

    <el-dialog
      :title="t('message.addFinanceRecord')"
      v-model="dialogVisible"
      width="50%"
    >
      <el-form :model="newRecord" @submit.prevent="submitRecord" label-width="120px">
        <el-form-item label="金额">
          <el-input v-model="newRecord.amount" type="number" placeholder="请输入金额"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="newRecord.type" placeholder="请选择类型">
            <el-option label="收入" value="income"></el-option>
            <el-option label="支出" value="expense"></el-option>
            <el-option label="资产" value="asset"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主类别">
          <el-select v-model="newRecord.category_id" placeholder="请选择主类别">
            <el-option v-for="category in categories" :key="category.id" :label="category.name" :value="category.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="子类别">
          <el-select v-model="newRecord.subcategory_id" placeholder="请选择子类别">
            <el-option v-for="subcategory in subcategories" :key="subcategory.id" :label="subcategory.name" :value="subcategory.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目">
          <el-input v-model="newRecord.project" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="newRecord.remarks" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker v-model="newRecord.date" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="收据">
          <el-upload
            class="upload-demo"
            action="#"
            :before-upload="handleUpload"
            :on-change="handleChange"
            list-type="picture"
          >
            <el-button type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitRecord">提交</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <AddFinancialAccount ref="addAccountDialog" @openDialog="showAddAccountDialog" />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ElMessage } from 'element-plus';
import api from '@/utils/api';
import { formatNumberWithCommasTwoDecimals } from '@/utils/formatNumber';
import SidebarMenu from '@/components/SidebarMenu.vue';
import { generateIntegerId } from '@/utils/snowflakeUtil';
import { ElMessageBox } from 'element-plus';
import AddFinancialAccount from '@/components/AddFinancialAccount.vue';
import axios from 'axios';
import { PRICE_API_URL } from '@/apiConfig';
import * as echarts from 'echarts'; // 引入 ECharts

export default {
  name: 'FinancePage',
  components: {
    SidebarMenu,
    AddFinancialAccount
  },
  setup() {
    const { t } = useI18n();
    const revenue = ref(0);
    const expenses = ref(0);
    const profit = ref(0);
    const undistributedProfit = ref(0);
    const totalNetProfit = ref(0);
    const unavailableUsdt = ref(0);

    const dailyProfits = ref([]); // 存储日利润数据

    const addAccountDialog = ref(null);

    const showAddAccountDialog = () => {
      console.log('showAddAccountDialog called', addAccountDialog.value);
      if (addAccountDialog.value) {
        addAccountDialog.value.openDialog();
      }
    };

    const newRecord = ref({
      amount: null,
      type: '',
      category_id: null,
      subcategory_id: null,
      project: '',
      remarks: '',
      date: '',
      receipt: null
    });

    const categories = ref([]);
    const subcategories = ref([]);

    const fetchFinanceData = async () => {
      try {
        const response = await api.get('/financial_summary');
        console.log('Financial Data:', response.data);
        revenue.value = response.data.revenue;
        expenses.value = response.data.expenses;
        profit.value = response.data.profit;
        undistributedProfit.value = response.data.total_undistributed_profit;
        totalNetProfit.value = response.data.total_net_profit;
        unavailableUsdt.value = response.data.unavailable_usdt;
      } catch (error) {
        console.error('获取财务数据出错:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await api.get('/categories');
        categories.value = response.data;
      } catch (error) {
        console.error('获取类别数据出错:', error);
      }
    };

    const fetchSubcategories = async () => {
      try {
        const response = await api.get('/subcategories');
        subcategories.value = response.data;
      } catch (error) {
        console.error('获取子类别数据出错:', error);
      }
    };

    const financialAccountsSummary = ref({
      bank_card: 0,
      cash: 0,
      exchange: 0,
      wallet: 0,
      unavailable_usdt: 0
    });

    const totalFinancialAccounts = computed(() => {
      return (
        financialAccountsSummary.value.bank_card +
        financialAccountsSummary.value.cash +
        financialAccountsSummary.value.exchange +
        financialAccountsSummary.value.wallet +
        financialAccountsSummary.value.unavailable_usdt
      );
    });

    const fetchFinancialAccountsSummary = async () => {
      try {
        const priceResponse = await axios.get(PRICE_API_URL);
        const exchangeRate = priceResponse.data.sellPrice;
        const response = await api.get('/financial_accounts/summary', {
          params: { exchange_rate: exchangeRate }
        });
        financialAccountsSummary.value = response.data.summary;
        console.log('財務賬戶摘要:', financialAccountsSummary.value);
      } catch (error) {
        console.error('獲取財務賬戶摘要出錯:', error);
      }
    };

    const fetchDailyProfits = async () => {
      try {
        const response = await api.get('/profits/daily_summary');
        console.log('最近30天的日利润数据:', response.data);
        dailyProfits.value = response.data.data
          .reverse() // 反转数组顺序
          .map(item => ({
            date: item.date,
            total_net_profit: Math.floor(item.total_net_profit) // 取整
          }));
        renderChart(); // 渲染图表
      } catch (error) {
        console.error('获取日利润数据出错:', error);
      }
    };

    const renderChart = () => {
      const chartDom = document.getElementById('daily-profit-chart');
      const myChart = echarts.init(chartDom);
      const option = {
        title: {
          text: '最近30天的日利润'
        },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          data: dailyProfits.value.map(item => item.date)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: dailyProfits.value.map(item => item.total_net_profit),
            type: 'line'
          }
        ]
      };
      myChart.setOption(option);
    };

    const handleUpload = () => {
      return false;
    };

    const handleChange = (file) => {
      newRecord.value.receipt = file.raw;
    };

    const resetForm = () => {
      newRecord.value = {
        amount: null,
        type: '',
        category_id: null,
        subcategory_id: null,
        project: '',
        remarks: '',
        date: '',
        receipt: null
      };
    };

    const dialogVisible = ref(false);

    const showAddFinanceDialog = () => {
      dialogVisible.value = true;
    };

    const submitRecord = () => {
      console.log('提交的记录:', newRecord.value);
      resetForm();
      dialogVisible.value = false;
    };

    onMounted(() => {
      fetchFinanceData();
      fetchCategories();
      fetchSubcategories();
      fetchFinancialAccountsSummary();
      fetchDailyProfits(); // 调用获取日利润数据的函数
    });

    const generatePeriod = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      let quarter;
      
      if (month <= 3) quarter = 'Q1';
      else if (month <= 6) quarter = 'Q2';
      else if (month <= 9) quarter = 'Q3';
      else quarter = 'Q4';
      
      const date = now.toISOString().split('T')[0].replace(/-/g, '');
      const randomPart = generateIntegerId(3);
      
      return `${quarter}_${year}${date}_${randomPart}`;
    };

    const showDistributeProfitDialog = async () => {
      try {
        if (undistributedProfit.value < 500) {
          ElMessageBox.alert(`當前未分配利潤為 ${formatNumberWithCommasTwoDecimals(undistributedProfit.value)}，低於500，無法進行分紅操作。`, '提示', {
            confirmButtonText: '確定'
          });
          return;
        }

        const confirmResult = await ElMessageBox.confirm(
          `當前未分配利潤為 ${formatNumberWithCommasTwoDecimals(undistributedProfit.value)}，是否繼續進行分紅操作？`,
          '確認分紅',
          {
            confirmButtonText: '繼續',
            cancelButtonText: '取消',
            type: 'warning'
          }
        );
        console.log('confirmResult:', confirmResult);

        if (confirmResult === 'confirm') {
          const period = generatePeriod();
          const plan_id = generateIntegerId(12);

          console.log('period:', period);
          console.log('plan_id:', plan_id);

          const response = await api.post('/dividend', {
            period: period,
            plan_id: plan_id
          });

          if (response.status === 200) {
            ElMessage.success('分紅操作成功');
            await fetchFinanceData();
          } else {
            ElMessage.error('分紅操作失敗');
          }
        }
      } catch (error) {
        if (error === 'cancel') {
          ElMessage.info('已取消分紅操作');
        } else {
          console.error('分紅操作出錯:', error);
          ElMessage.error('分紅操作出錯，請稍後重試');
        }
      }
    };

    return {
      t,
      revenue,
      expenses,
      profit,
      undistributedProfit,
      totalNetProfit,
      unavailableUsdt,
      formatNumberWithCommasTwoDecimals,
      newRecord,
      categories,
      subcategories,
      handleUpload,
      handleChange,
      submitRecord,
      resetForm,
      dialogVisible,
      showAddFinanceDialog,
      showDistributeProfitDialog,
      showAddAccountDialog,
      addAccountDialog,
      financialAccountsSummary,
      totalFinancialAccounts
    };
  }
};
</script>

<style scoped>
.finance-container {
  padding: 20px;
}

.form-card {
  width: 100%;
}

.finance-content {
  margin-top: 20px;
  flex-grow: 1; /* 使内容区域占据剩余空间 */
}

.el-card {
  text-align: center;
  margin-bottom: 20px; /* 增加行间距 */
}

.action-buttons {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* 按钮之间的间距 */
  z-index: 1000;
}

.action-button {
  width: 140px; /* 设置按钮宽度，使两个按钮宽度一致 */
}

/* 移除之前单独的按钮样式 */
.add-finance-button,
.distribute-profit-button {
  /* 这些样式可以删除，因为不再需要单独定位 */
}
</style>