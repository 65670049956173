<template>
    <el-dialog :title="t('message.addFinancialAccount')" v-model="dialogVisible" width="50%">
        <el-form :model="newAccount" @submit.prevent="submitAccount" label-width="120px">
            <el-form-item label="名稱">
                <el-input v-model="newAccount.name" placeholder="請輸入名稱"></el-input>
            </el-form-item>
            <el-form-item label="類型">
                <el-select v-model="newAccount.type" placeholder="請選擇類型">
                    <el-option label="銀行卡" value="bank_card"></el-option>
                    <el-option label="錢包" value="wallet"></el-option>
                    <el-option label="交易所" value="exchange"></el-option>
                    <el-option label="現金" value="cash"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="餘額 (HKD)">
                <el-input v-model="newAccount.balance_hkd" type="number" placeholder="請輸入餘額"></el-input>
            </el-form-item>
            <el-form-item label="提現限額">
                <el-input v-model="newAccount.withdrawal_limit" type="number" placeholder="請輸入提現限額"></el-input>
            </el-form-item>
            <el-form-item label="銀行ID">
                <el-input v-model="newAccount.bank_id" placeholder="請輸入銀行ID"></el-input>
            </el-form-item>
            <el-form-item label="卡號">
                <el-input v-model="newAccount.card_number" placeholder="請輸入卡號"></el-input>
            </el-form-item>
            <el-form-item label="銀行名稱">
                <el-input v-model="newAccount.bank_name" placeholder="請輸入銀行名稱"></el-input>
            </el-form-item>
            <el-form-item label="FPS ID">
                <el-input v-model="newAccount.fps_id" placeholder="請輸入FPS ID"></el-input>
            </el-form-item>
            <el-form-item label="FPS 電話">
                <el-input v-model="newAccount.fps_phone" placeholder="請輸入FPS 電話"></el-input>
            </el-form-item>
            <el-form-item label="FPS 電郵">
                <el-input v-model="newAccount.fps_email" placeholder="請輸入FPS 電郵"></el-input>
            </el-form-item>
            <el-form-item label="可用 USDT 餘額">
                <el-input v-model="newAccount.balance_usdt_available" type="number"
                    placeholder="請輸入可用 USDT 餘額"></el-input>
            </el-form-item>
            <el-form-item label="不可用 USDT 餘額">
                <el-input v-model="newAccount.balance_usdt_unavailable" type="number"
                    placeholder="請輸入不可用 USDT 餘額"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitAccount">提交</el-button>
                <el-button @click="resetForm">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script setup>
import { ref, defineExpose } from 'vue';
import { useI18n } from 'vue-i18n';
import { ElMessage } from 'element-plus';
import api from '@/utils/api';

const { t } = useI18n();
const dialogVisible = ref(false);

const newAccount = ref({
  name: '',
  type: '',
  balance_hkd: null,
  withdrawal_limit: null,
  bank_id: '',
  card_number: '',
  bank_name: '',
  fps_id: '',
  fps_phone: '',
  fps_email: '',
  balance_usdt_available: null,
  balance_usdt_unavailable: null
});

const resetForm = () => {
  newAccount.value = {
    name: '',
    type: '',
    balance_hkd: null,
    withdrawal_limit: null,
    bank_id: '',
    card_number: '',
    bank_name: '',
    fps_id: '',
    fps_phone: '',
    fps_email: '',
    balance_usdt_available: null,
    balance_usdt_unavailable: null
  };
};

const submitAccount = async () => {
  try {
    console.log('提交的賬戶數據:', newAccount.value); // 添加日誌記錄
    const response = await api.post('/financial_accounts', newAccount.value);
    if (response.status === 200) {
      ElMessage.success('賬戶添加成功');
      resetForm();
      dialogVisible.value = false;
    } else {
      console.error('賬戶添加失敗:', response); // 添加日誌記錄
      ElMessage.error('賬戶添加失敗');
    }
  } catch (error) {
    console.error('賬戶添加出錯:', error.response ? error.response.data : error); // 添加日誌記錄
    ElMessage.error('賬戶添加出錯，請稍後重試');
  }
};

const openDialog = () => {
  dialogVisible.value = true;
};

defineExpose({
  openDialog
});
</script>
<style scoped>
/* 添加樣式 */
</style>