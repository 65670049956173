export default {
    message: {
        language: 'Language',
        title: 'Rice Cryptocurrency Exchange',
        type: 'Transaction Type',
        buy: 'Buy',
        sell: 'Sell',
        amount: 'HKD Amount',
        submit: 'Submit',
        amountError: 'Amount must be a multiple of 100 and at least {minAmount} HKD',
        exchangeRateError: 'Failed to fetch exchange rate, please try again later',
        walletAddress: 'Wallet Address',
        enterWalletAddress: 'Enter your wallet address',
        chain: 'Chain Name',
        exchangeRate: 'Exchange Rate',
        amountHint: 'The amount must be a multiple of 100, with a minimum of {minAmount} HKD',
        usdtAmount: 'USDT Amount',
        orderNumber: 'Order Number',
        paymentAddress: 'Payment Address',
        copyAddress: 'Copy Address',
        copyFailed: 'Copy failed',
        addressCopied: 'Address copied successfully',
        reset: 'Order Again',
        returnToHome: 'Return to Home',
        walletAddressError: 'Wallet address is required',
        invalidTronAddress: 'Invalid Tron wallet address',
        invalidBscAddress: 'Invalid Binance Smart Chain (BSC) wallet address',
        orderSubmitted: 'Order submitted successfully!',
        submitFailed: 'Failed to submit the order.',
        adjustAmount: 'Adjust Amount',
        enterUSDT: 'Enter USDT Amount',
        viewReceipt: 'View Receipt',
        pendingOrders: "Pending Orders",
        noPendingOrders: "No pending orders",
        statusPending: "Pending",
        statusCompleted: "Completed",
        statusClosed: "Closed",
        edit: "Edit",
        markCompleted: "Mark as Completed",
        closeOrder: "Close Order",
        resetOrder: "Reset Order",
        uploadImage: "Take a Photo",
        receiptTitle: 'Rice Cryptocurrency Exchange',
        orderTime: 'Order Creation Date',
        printTime: 'Receipt Print Time',
        receiptDeclaration: 'Receipt Declaration',
        receiptDeclarationContent: 'I have verified the transaction details and payment information above and confirmed their accuracy. Once the Rice shop transfers USDT to the address provided, any subsequent changes in the USDT at this address will be solely your responsibility. Please ensure the security of your wallet address to avoid any potential loss of funds. Additionally, please confirm that you fully understand and acknowledge that this purchase is made by you and that you take full responsibility for this action.',
        printReceipt: 'Print Receipt',
        orderType: "Transaction Type",
        buyOrder: "Buy",
        sellOrder: "Sell",
        storeAddress: "Store Address",
        storeAddressContent: "Shop 150A, 1st Floor, President Commercial Centre, 608 Nathan Road, Mongkok, Kowloon, Hong Kong",
        sellReceiptDeclarationContent: 'I have verified the above transaction details and receipt information and confirm that they are accurate. I have received the due cash and confirm that the exchange between USDT and cash is fully settled. Please confirm that this transaction is conducted solely by you in person within the store. Please note that after this transaction is confirmed on-site, Rice is not responsible for any issues once you leave the premises.',
        startScanningError: 'Error occurred while starting scanning',
        deviceNotFound: 'Requested device not found',
        cameraNotSupported: 'Your device does not support camera functionality',
        scanningError: 'An error occurred during scanning',
        startScanning: 'Start Scanning',
        stopScanning: 'Stop Scanning',
        dashboard: 'Dashboard',
        todayTransactions: "Today's Transactions",
        todayProfit: "Today's Profit",
        completedOrders: 'Completed Orders',
        viewPendingOrders: 'View Pending Orders',
        status: 'Status',
        selectStatus: 'Select Status',
        startTime: 'Start Time',
        endTime: 'End Time',
        startDate: 'Start Date',
        endDate: 'End Date',
        search: 'Search',
        settings: 'Settings',
        buyPrice: 'Buy Price',
        sellPrice: 'Sell Price',
        setQuotes: 'Set Quotes',
        save: 'Save',
        memberLogin: 'Member Login',
        email: 'Email',
        password: 'Password',
        login: 'Login',
        memberPage: 'Member Page',
        welcome: 'Welcome',
        sendCode: 'Send Verification Code',
        verificationCode: 'Verification Code',
        buyUSDT: 'Buy USDT',
        hkdToUSDT: 'HKD to USDT',
        orderManagement: 'Order Management',
        saveSuccess: 'Save Success',
        logout: 'Logout',
        loading: 'Loading...',
        receiveAddress: 'Receive Address',
        createdAt: 'Created At',
        fee: 'Fee',
        transactionHash: 'Transaction Hash',
        remark: 'Remark',
        sendAddress: 'Send Address',
        all: 'All',
        amountInput: 'Please enter USDT amount or HKD amount',
        sellUSDT: 'Sell USDT',
        USDTToHKD: 'USDT To HKD',
        hkdFee: 'HKD Fee',
        feeApplied: "Order amount below {amount} HKD, fee of {fee} HKD will be applied",
        binanceOnlineOrder: 'Binance Online Order',
        feeHKD: 'HKD Fee',
        finance: 'Finance', // 新增翻译
        revenue: 'Revenue', // 新增翻译
        expenses: 'Expenses', // 新增翻译
        profit: 'Profit', // 新增翻译
        undistributedProfit: 'Undistributed Profit', // 新增翻译
        addFinanceRecord: 'Add Finance Record', // 新增翻译
        typeFinance: 'Type',
        categoryFinance: 'Category',
        subcategoryFinance: 'Subcategory',
        projectFinance: 'Project',
        remarksFinance: 'Remarks',
        todayHKDBuy: 'Today HKD Buy',
        todayHKDSell: 'Today HKD Sell',
        profitRate: 'Profit Rate',
        loadingDashboardData: 'Loading Dashboard Data...',
        empty: 'Empty',
        out: 'Out',
        promptStatus: 'Prompt Status',
        otherInfo: 'Other Info',
        outMessage: 'Out Message',
        selectPromptStatus: 'Select prompt status',
        monthProfit: 'Month Profit',
        viewPrice: 'User Interaction Screen',
        busyMessage: 'Busy Message',
        closedMessage: 'Closed Message',
        openMessage: 'Open Message',
        financialAccounts: 'Financial Accounts',
        rmbAmount: 'RMB Amount',
        cancel: 'Cancel',
        enterPlatformOrGasFee: 'Enter Platform or Gas Fee',
        enterHKDFee: 'Enter HKD Fee',
        platformGasFeeUSDT: 'Platform/Gas Fee (USDT)',
        chainType: 'Chain Type',
        sendAddressType: 'Send Address Type',
        editOrder: 'Edit Order',
        receiveAddressType: 'Receive Address Type',
        selectReceiveAddressType: 'Select Receive Address Type',
        exchange: 'Exchange',
        wallet: 'Wallet',
        selectSendAddress: 'Select Send Address',
        paymentChannelReceiveAddress: 'Payment Channel Receive Address',
        enterUSDTAmount: 'Enter USDT Amount',
        enterHKDAmount: 'Enter HKD Amount',
        enterRateValue: 'Enter Rate Value',
        editSuccess: 'Edit Success',
        buyPriceLarge: 'Large Buy Price',
        sellPriceLarge: 'Large Sell Price',
        largeOrderThreshold: 'Large Order Threshold',
        annualizedReturn: 'Annualized Return',
        accountTransfer: 'Account Transfer',
        frequentlyUsed: 'Frequently Used',
        sourceAccount: 'Source Account',
        targetAccount: 'Target Account',
        hkdAmount: 'HKD Amount',
        confirm: 'Confirm',
        accountTransferSuccess: 'Account transfer successful',
        orderAmountNotMultipleOfHundred: 'Order amount is not a multiple of 100',
        orderAmountError: 'Order amount must be at least {minAmount} HKD',
        usdtOrHkdAction: 'Enter order amount',
        viewImage: 'View Image',
        close: 'Close',
        rotateImage: 'Rotate 180 degrees',
        selfServiceOrder: 'Self Service Order',
    },
    menu: {
        dashboard: 'Dashboard',
        setQuotes: 'Set Quotes',
        price: 'Price'
    },
    price: {
        buyUSDT: 'Buy USDT',
        hkdToUSDT: 'HKD to USDT',
        sellUSDT: 'Sell USDT',
        USDTToHKD: 'USDT to HKD',
        minOrderAmount: 'Minimum order amount: {amount}',
        feeApplied: 'Order amount below {amount} HKD, fee of {fee} HKD will be applied',
        largeOrderThreshold: 'Large',
        receiveUsdtAddressQrCode: 'Receive USDT Address QR Code',
        chainName: 'Chain Name',
        receiveUsdtAmount: 'Receive USDT Amount',
        orderDetails: 'Order Details',
        orderNumber: 'Order Number',
        orderType: 'Order Type',
        customerReceiveHkd: 'Customer Receive HKD',
        customerReceiveUsdt: 'Customer Receive USDT',
        largeOrderMessage: 'Order amount reached {amount} HKD, enjoy large order price',
        printReceipt: 'Print Receipt',
        printA4Receipt: 'Print A4 Receipt',
        fullscreen: 'Fullscreen',
        shopName: 'Rice Cryptocurrency Exchange',
        home: 'Home',
        buy: 'Buy',
        sell: 'Sell',
        open: 'Open',
        busy: 'Busy',
        out: 'Out',
        closed: 'Closed',
        noFeeAbove500: 'Sell USDT amount above 500HKD, no fee',
        hkdAmount: 'HKD Amount',
        exchangeRate: 'Exchange Rate',
        fee: 'Fee',
        amount: 'Amount',
        createdTime: 'Created Time',
        printRecordAddedSuccess: 'Print record added successfully',
        printRecordAddFailed: 'Failed to add print record',
        usdtAmount: 'USDT Amount',
    }
};