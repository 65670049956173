<template>
  <div class="display-data">
    <div class="channel-input">
      <label for="channel">{{ $t('message.enterChannel') }}</label>
      <input type="text" v-model="channel" @change="saveChannel" placeholder="Enter channel" />
    </div>
    <div class="data-display">
      <h2>{{ $t('message.displayData') }}</h2>
      <p v-if="data">{{ data }}</p>
      <p v-else>{{ $t('message.noData') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

export default {
  name: 'DisplayData',
  setup() {
    const channel = ref(localStorage.getItem('channel') || '0');
    const data = ref('');

    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/display_data/${channel.value}`);
        data.value = response.data.content || 'No data available';
      } catch (error) {
        console.error('Error fetching data:', error);
        data.value = 'Error fetching data';
      }
    };

    const saveChannel = () => {
      localStorage.setItem('channel', channel.value);
      fetchData();
    };

    onMounted(() => {
      fetchData();
    });

    return {
      channel,
      data,
      saveChannel,
    };
  },
};
</script>

<style scoped>
.display-data {
  padding: 20px;
  background-color: #2c2c2c;
  color: #f0f0f0;
}

.channel-input {
  margin-bottom: 20px;
}

.channel-input label {
  margin-right: 10px;
}

.data-display {
  background-color: #343a40;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>

