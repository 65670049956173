<template>
  <div class="set-quotes">
    <el-row>
      <el-col :span="4">
        <SidebarMenu />
      </el-col>
      <el-col :span="18" class="content-col">
        <h1>{{ t("message.setQuotes") }}</h1>
        <el-form :model="form" label-width="180px" @submit.prevent="saveQuotes">
          <el-form-item :label="t('message.buyPrice')">
            <el-input v-model="form.buyPrice" type="number" step="0.001"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.sellPrice')">
            <el-input v-model="form.sellPrice" type="number" step="0.001"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.buyPriceLarge')">
            <el-input v-model="form.buyPriceLarge" type="number" step="0.001"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.sellPriceLarge')">
            <el-input v-model="form.sellPriceLarge" type="number" step="0.001"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.largeOrderThreshold')">
            <el-input v-model="form.largeOrderThreshold" type="number" step="1"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.busyMessage')">
            <el-input v-model="form.messages.busy.zh" :placeholder="t('message.chinese')"></el-input>
            <el-input v-model="form.messages.busy.en" :placeholder="t('message.english')"></el-input>
            <el-input v-model="form.messages.busy.cn" :placeholder="t('message.traditionalChinese')"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.closedMessage')">
            <el-input v-model="form.messages.closed.zh" :placeholder="t('message.chinese')"></el-input>
            <el-input v-model="form.messages.closed.en" :placeholder="t('message.english')"></el-input>
            <el-input v-model="form.messages.closed.cn" :placeholder="t('message.traditionalChinese')"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.openMessage')">
            <el-input v-model="form.messages.open.zh" :placeholder="t('message.chinese')"></el-input>
            <el-input v-model="form.messages.open.en" :placeholder="t('message.english')"></el-input>
            <el-input v-model="form.messages.open.cn" :placeholder="t('message.traditionalChinese')"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.outMessage')">
            <el-input v-model="form.messages.out.zh" :placeholder="t('message.chinese')"></el-input>
            <el-input v-model="form.messages.out.en" :placeholder="t('message.english')"></el-input>
            <el-input v-model="form.messages.out.cn" :placeholder="t('message.traditionalChinese')"></el-input>
          </el-form-item>
          <el-form-item :label="t('message.promptStatus')">
            <el-select v-model="form.promptStatus" :placeholder="t('message.selectPromptStatus')">
              <el-option :label="t('message.openMessage')" value="open"></el-option>
              <el-option :label="t('message.outMessage')" value="out"></el-option>
              <el-option :label="t('message.busyMessage')" value="busy"></el-option>
              <el-option :label="t('message.closedMessage')" value="closed"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveQuotes">{{ t("message.save") }}</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useUserStore } from '@/stores/user';
import SidebarMenu from '@/components/SidebarMenu.vue';
import { ElMessage } from 'element-plus';
import api from '@/utils/api';

export default {
  name: 'SetQuotesView',
  components: {
    SidebarMenu
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const userStore = useUserStore();

    const form = ref({
      buyPrice: '',
      sellPrice: '',
      buyPriceLarge: '',
      sellPriceLarge: '',
      largeOrderThreshold: '',
      messages: {
        busy: { zh: '', en: '', cn: '' },
        closed: { zh: '', en: '', cn: '' },
        open: { zh: '', en: '', cn: '' },
        out: { zh: '', en: '', cn: '' }
      },
      promptStatus: ''
    });

    const fetchQuotes = async () => {
      try {
        const response = await axios.get(`https://uprice.pcdao.org/api/price`);
        console.log(response.data);
        form.value.buyPrice = response.data.buyPrice;
        form.value.sellPrice = response.data.sellPrice;
        form.value.buyPriceLarge = response.data.buyPriceLarge;
        form.value.sellPriceLarge = response.data.sellPriceLarge;
        form.value.largeOrderThreshold = response.data.largeOrderThreshold;
        form.value.messages = response.data.messages || {
          busy: { zh: '', en: '', cn: '' },
          closed: { zh: '', en: '', cn: '' },
          open: { zh: '', en: '', cn: '' },
          out: { zh: '', en: '', cn: '' }
        };
        form.value.promptStatus = response.data.promptStatus || '';
      } catch (error) {
        console.error('获取报价失败:', error);
        handleApiError(error);
      }
    };

    const saveQuotes = async () => {
      try {
        console.log('保存报价:', form.value);
        await api.post('/update_price', form.value);
        ElMessage.success(t('message.saveSuccess'));
      } catch (error) {
        console.error('保存报价失败:', error);
        handleApiError(error);
      }
    };

    const handleApiError = (error) => {
      if (error.response && error.response.status === 401) {
        console.log('未授权访问，重定向到登录页面');
        router.push('/login');
      } else if (error.response && error.response.status === 403) {
        console.log('禁止访问，用户不是管理员');
        ElMessage.error(t('message.noPermission'));
        router.push('/dashboard');
      } else {
        ElMessage.error(t('message.operationFailed'));
      }
    };

    onMounted(async () => {
      if (!userStore.isLoggedIn) {
        console.log('用户未登录，重定向到登录页面');
        router.push('/login');
      } else {
        console.log('用户已登录，获取报价数据');
        await fetchQuotes();
      }
    });

    return {
      t,
      form,
      saveQuotes
    };
  }
};
</script>

<style scoped>
.set-quotes {
  padding: 20px;
}
.content-col {
  margin-left: 20px;
  margin-right: 20px;
}
</style>